import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  Box,
  CircularProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ProgrammRecording from "../../components/_dashboard/programmes/ProgrammeRecording";
import ResourcesCard from "../../components/_dashboard/programmes/ResourcesCard";
import {
  lessonDetail,
  markelessonCompleted,
} from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import { ReactVideoPlayer } from "src/components";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import CustomPopover from "../../components/CustomPopover";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import {
  AddNote,
  EditNote,
  DeleteNote,
} from "../..//DAL/Programmes/Lessons/Notes/Notes";
import CustomConfirmation from "src/components/CustomConfirmation";
import { format_date_with_timezone } from "src/utils/formatTime";
import { get_date_with_user_time_zone } from "src/utils/constants";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
const ProgrammesImgStyle = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "cover",
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function LessonsDetail(props) {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);
  const [lessonsDetail, setLessonsDetail] = useState({});
  const [resourcesList, setResourcesList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [nextLesson, setNextLesson] = useState({});
  const [previousLesson, setPreviousLesson] = useState({});
  const [notesList, setNotesList] = useState([]);
  const [inputLessonNote, setInputLessonNote] = React.useState("");
  const [lessonSlug, setLessonSlug] = useState("");
  const [completeLessonSlug, setCompleteLessonSlug] = useState("");
  const location = useLocation();
  const [noteId, setNoteId] = React.useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [openMarkComplete, setOpenMarkComplete] = useState(false);
  const { userInfo, adminTimeZone } = useContentSetting();
  const [showButtons, setShowButtons] = React.useState({
    addButton: true,
    editButton: false,
  });
  const [value, setValue] = React.useState(0);
  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };

  //Changing tab values
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Getting lesson detail
  const getLesonDetail = async () => {
    const result = await lessonDetail(params.lesson_slug);
    if (result.code === 200) {
      setLessonsDetail(result.lesson);
      setResourcesList(result.document_list);
      setRecordingList(result.recording_list);
      setNotesList(result.notes_list);
      setNextLesson(result.next_lesson);
      setPreviousLesson(result.previous_lesson);
      setIsLoading(false);
    } else {
      // setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  const handleMarkComplete = (lesson_slug) => {
    setCompleteLessonSlug(lesson_slug);
    setOpenMarkComplete(true);
  };

  const mrakCompleted = async () => {
    setOpenMarkComplete(false);
    setIsLoading(true);
    const result = await markelessonCompleted(completeLessonSlug);
    if (result.code === 200) {
      getLesonDetail();
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const showPreviouLesson = () => {
    navigate(`/lessons/${previousLesson.lesson_slug}`);
  };
  const showNextLesson = () => {
    navigate(`/lessons/${nextLesson.lesson_slug}`);
  };

  //Changing Textarea values with onchange
  const handleNoteChange = (event) => {
    const value = event.target.value;
    setInputLessonNote(value);
  };
  //Adding Note
  const handleSubmit = async (e) => {
    setIsLoadingNotes(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("lesson_note", inputLessonNote);
    formData.append("lesson_slug", params.lesson_slug);
    formData.append("program_slug", lessonsDetail.program[0]._id.program_slug);
    setInputLessonNote("");
    const result = await AddNote(formData);
    if (result.code === 200) {
      setInputLessonNote("");
      getLesonDetail();
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setTimeout(() => {
      setIsLoadingNotes(false);
    }, 500);
  };

  //Getting Note In textarea
  const handleEdit = (note) => {
    setInputLessonNote(note.lesson_note);
    setNoteId(note._id);
    setShowButtons({
      addButton: false,
      editButton: true,
    });

    const notes = document.getElementById("notes-input");
    setTimeout(() => {
      notes.scrollIntoView();
      notes.focus();
    }, 500);
  };

  const handleCancelUpdate = () => {
    setInputLessonNote("");
    setNoteId("");
    setShowButtons({
      addButton: true,
      editButton: false,
    });
  };

  const handleAgreeDelete = (note) => {
    setNoteId(note._id);
    setOpenDelete(true);
  };

  //Updating Note
  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsLoadingNotes(true);
    const formData = new FormData();
    formData.append("lesson_note", inputLessonNote);
    const result = await EditNote(noteId, formData);
    if (result.code === 200) {
      getLesonDetail();
      setInputLessonNote("");
      setNoteId(0);
      setShowButtons({
        addButton: true,
        editButton: false,
      });
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
    setIsLoadingNotes(false);
  };

  //Deleting Note
  const handleDelete = async () => {
    setOpenDelete(false);
    const result = await DeleteNote(noteId);
    if (result.code === 200) {
      getLesonDetail();
      setIsLoading(false);
      enqueueSnackbar(result.message, { variant: "success" });
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLesonDetail();
  }, [params.lesson_slug]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() =>
              navigate(
                `/programmes/${lessonsDetail.program[0]._id.program_slug}/lessons`
              )
            }
          >
            <ArrowBackIcon />
          </IconButton>
          {lessonsDetail.lesson_completion_status === true ? (
            <button
              className="small-contained-button float-end mt-1 disabled"
              disabled="disabled"
            >
              Completed
            </button>
          ) : (
            <button
              className="small-contained-button float-end mt-1"
              onClick={() => {
                handleMarkComplete(lessonsDetail.lesson_slug);
              }}
            >
              Mark Complete
            </button>
          )}
        </div>
      </div>

      <div className="row section-space">
        <div className="col-12">
          <h1 className="programmes-heading">
            {htmlDecode(lessonsDetail.title)}
          </h1>
        </div>
      </div>
      <div className="row media-margin">
        <div className="col-12">
          {lessonsDetail.landing_lesson_video_url ? (
            <ReactVideoPlayer url={lessonsDetail.landing_lesson_video_url} />
          ) : (
            <ProgrammesImgStyle
              src={s3baseUrl + lessonsDetail.lesson_images.thumbnail_1}
            />
          )}
        </div>
        <div className="col-12 section-space">
          {Object.entries(previousLesson).length > 0 && (
            <button
              className="small-contained-button"
              onClick={showPreviouLesson}
            >
              {"<<"} Previous
            </button>
          )}
          {Object.entries(nextLesson).length > 0 && (
            <button
              className="small-contained-button float-end"
              onClick={showNextLesson}
            >
              Next {">>"}
            </button>
          )}
        </div>

        {lessonsDetail.audio_file && (
          <div className="col-12 mt-3 text-center">
            <div>
              <audio
                controlsList="nodownload"
                className="w-100"
                src={s3baseUrl + lessonsDetail.audio_file}
                controls
              />
            </div>
          </div>
        )}
        <div className="col-12 section-space set-image-center">
          <div
            dangerouslySetInnerHTML={{
              __html: lessonsDetail.detailed_description,
            }}
          ></div>
        </div>
        <div className="col-12 section-space">
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                // allowScrollButtonsMobile="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab label="Recordings" {...a11yProps(0)} />
                <Tab label="Resources" {...a11yProps(1)} />
                <Tab label="Notes" {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <div className="row">
                {recordingList.length > 0 ? (
                  recordingList.map((recording, i) => (
                    <div className="col-12 mt-3">
                      <ProgrammRecording
                        recording={recording}
                        programSlug={lessonsDetail.program[0]._id.program_slug}
                        lessonSlug={params.lesson_slug}
                      />
                    </div>
                  ))
                ) : (
                  <RecordNotFound title="Recording" />
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="row">
                {resourcesList.length > 0 ? (
                  resourcesList.map((resource, i) => (
                    <div className="col-lg-6 col-md-6 col-sm-12 mt-3 d-flex">
                      <ResourcesCard
                        imageLink={imageLinks}
                        resource={resource}
                      />
                    </div>
                  ))
                ) : (
                  <RecordNotFound title="Resource" />
                )}
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="row">
                {notesList.map((note, index) => (
                  <div className="col-12 section-space" key={note._id}>
                    <div className="card">
                      <div className="card-body notes-input set-display-inline">
                        <CustomPopover menu={MENU_OPTIONS} data={note} />
                        <p className="mb-0 normal-text">
                          {htmlDecode(note.lesson_note)}
                        </p>
                        <p className="lesson-notes-title mt-2 mb-1 date-color">
                          {get_date_with_user_time_zone(
                            note.lesson_note_date,
                            "DD-MM-YYYY hh:mm:ss",
                            userInfo.time_zone,
                            adminTimeZone
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <form
                    onSubmit={
                      showButtons.addButton ? handleSubmit : handleUpdate
                    }
                  >
                    <span
                      htmlFor="exampleFormControlInput1"
                      className="form-label-lesson"
                    >
                      Lesson Notes:
                    </span>
                    <textarea
                      required
                      rows="5"
                      id="notes-input"
                      className="form-control text-area-task mt-2"
                      value={inputLessonNote}
                      onChange={handleNoteChange}
                      // onFocus={inputLessonNote}
                    />
                    {showButtons.addButton === true && (
                      <button
                        type="submit"
                        className="mt-2 float-end small-contained-button"
                        disabled={isLoadingNotes}
                      >
                        {isLoadingNotes ? "Saving..." : "Save"}
                      </button>
                    )}
                    {showButtons.editButton === true && (
                      <div className="d-flex justify-content-end">
                        <button
                          className="mt-2 me-2 small-contained-button"
                          onClick={handleCancelUpdate}
                        >
                          Cancel
                        </button>
                        <button
                          className="mt-2 small-contained-button"
                          type="submit"
                          disabled={isLoadingNotes}
                        >
                          {isLoadingNotes ? "Updating..." : "Update"}
                        </button>
                      </div>
                    )}
                    <CustomConfirmation
                      open={openDelete}
                      setOpen={setOpenDelete}
                      title={"Are you sure you want to delete this note?"}
                      handleAgree={handleDelete}
                    />
                  </form>
                </div>
              </div>
            </TabPanel>
          </Box>
        </div>
      </div>
      <CustomConfirmation
        open={openMarkComplete}
        setOpen={setOpenMarkComplete}
        title={"Are you sure you want to mark this lesson as completed?"}
        handleAgree={mrakCompleted}
      />
    </div>
  );
}

export default LessonsDetail;
