import React from "react";
import ReactPlayer from "react-player";

export default function ReactNormailVideoPlayer(props) {
  return (
    <ReactPlayer
      controls
      url={props.url}
      width="100%"
      pip
      stopOnUnmount
      playsinline
      // volume={0}
      className="set-max-height"
      // muted={0}
    />
  );
}
