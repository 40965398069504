import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import RatingCard from "./RatingCard";
import ProgrammRecording from "./ProgrammeRecording";
import LessonsCard from "./LessonsCard";
import StudySessionCard from "./StudySessionCard";
import ResourcesCard from "./ResourcesCard";
import { ReactVideoPlayer } from "src/components";
import { NoAccess, RecordNotFound } from ".";
import {
  csvImage,
  docImage,
  pdfImage,
  wordImage,
  audioImage,
  xlsxImage,
  otherImage,
} from "src/assets";
import { htmlDecode } from "src/utils/convertHtml";
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"span"} variant={"body2"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProgrammDetailTab({
  lessons,
  resources,
  reviews,
  recordingList,
  programSection,
  programLockStatus,
  programSlug,
  studySession,
}) {
  const [value, setValue] = React.useState(0);

  const imageLinks = {
    docx: wordImage,
    mp3: audioImage,
    pdf: pdfImage,
    csv: csvImage,
    doc: docImage,
    xlsx: xlsxImage,
    xls: xlsxImage,
    other: otherImage,
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  console.log(value + "value");
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          // allowScrollButtonsMobile="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Lessons" {...a11yProps(0)} />
          <Tab label="Recordings" {...a11yProps(1)} />
          <Tab label="Resources" {...a11yProps(2)} />
          <Tab label="Reviews" {...a11yProps(3)} />
          {studySession.length > 0 && (
            <Tab label="Study Session" {...a11yProps(4)} />
          )}

          {programSection &&
            programSection.map((programSection) => (
              <Tab
                key={programSection._id}
                label={htmlDecode(programSection.title)}
                {...a11yProps(4)}
              />
            ))}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <div className="row">
          {lessons.length > 0 ? (
            lessons.map((lesson) => (
              <div className="col-12 mt-3" key={lesson._id}>
                <LessonsCard
                  programLockStatus={programLockStatus}
                  programSlug={programSlug}
                  lesson={lesson}
                />
              </div>
            ))
          ) : (
            <RecordNotFound title="Lessons" />
          )}
        </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
        {programLockStatus === false ? (
          <div className="row">
            {recordingList.length > 0 ? (
              recordingList.map((recording, i) => (
                <div className="col-12 mt-3" key={recording._id}>
                  <ProgrammRecording
                    programSlug={programSlug}
                    recording={recording}
                  />
                </div>
              ))
            ) : (
              <RecordNotFound title="Recordings" />
            )}
          </div>
        ) : (
          <NoAccess title="Recordings" />
        )}
      </TabPanel>

      <TabPanel value={value} index={2}>
        {programLockStatus === false ? (
          <div className="row">
            {resources.length > 0 ? (
              resources.map((resource) => (
                <div
                  className="col-lg-6 col-md-6 col-sm-12 mt-3 d-flex"
                  key={resource._id}
                >
                  <ResourcesCard imageLink={imageLinks} resource={resource} />
                </div>
              ))
            ) : (
              <RecordNotFound title="Resource" />
            )}
          </div>
        ) : (
          <NoAccess title="Resource" />
        )}
      </TabPanel>
      <TabPanel value={value} index={3}>
        <div className="row mt-3">
          <div className="col-12">
            <h2>WHAT OUR CLIENTS SAY</h2>
            <p className="normal-font">
              It is for anyone who wants calmness and serenity in their life,
              and it’s perfect for people that crave clarity and direction and
              are ready to uplevel their thinking and results.
            </p>
          </div>
          {reviews.length > 0 ? (
            reviews.map((review, i) => (
              <div
                className="col-lg-4 col-md-6 col-sm-12 mt-4 d-flex"
                key={review._id}
              >
                <RatingCard review={review} />
              </div>
            ))
          ) : (
            <RecordNotFound title="Reviews" />
          )}
        </div>
      </TabPanel>
      {studySession.length > 0 && (
        <TabPanel value={value} index={4}>
          <div className="row">
            {studySession.map((study_session) => (
              <div className="col-12 mt-3" key={study_session._id}>
                <StudySessionCard
                  imageLink={imageLinks}
                  study_session_slug={study_session.study_session_slug}
                  studySession={study_session}
                />
              </div>
            ))}
          </div>
        </TabPanel>
      )}

      {programSection &&
        programSection.map((programSection, sectionIndex) => (
          <TabPanel
            value={value}
            index={
              studySession.length > 0 ? 5 + sectionIndex : 4 + sectionIndex
            }
            key={programSection._id}
          >
            <div className="row mt-3 program-sections">
              {programSection.video_url && (
                <div className="col-12">
                  <ReactVideoPlayer url={programSection.video_url} />
                </div>
              )}
              <div className="col-12 text-left section-space">
                <div
                  dangerouslySetInnerHTML={{
                    __html: programSection.detailed_description,
                  }}
                ></div>
              </div>
              {programSection.button_text && (
                <div className="text-end">
                  <a target="_blank" href={programSection.button_url}>
                    <button
                      className={`small-contained-button ${
                        programSection.button_url === "" ? "disabled" : ""
                      }`}
                      disabled={programSection.button_url === "" ? true : false}
                    >
                      {htmlDecode(programSection.button_text)}
                    </button>
                  </a>
                </div>
              )}
            </div>
          </TabPanel>
        ))}
    </Box>
  );
}
