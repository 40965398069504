import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { study_session_list } from "../../DAL/Programmes/Programmes";
import { s3baseUrl } from "../../config/config";
import CustomMUICard from "src/components/GeneralComponents/CustomMUICard";
import { RecordNotFound } from "src/components/_dashboard/programmes";
// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function StudySessionList() {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [studySessionList, setStudySessionList] = useState({});

  const handleClick = (object) => {
    navigate(
      `/study-session/${object.study_session_slug}/study-session-recordings`
    );
  };

  const getStudySessionDetail = async () => {
    const result = await study_session_list();
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.study_session.map((study_session_category) => {
        CARD_OPTIONS.push({
          ...study_session_category,
          card_title: study_session_category.title,
          card_short_description: study_session_category.short_description,
          card_image: s3baseUrl + study_session_category.image.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
        });
      });
      setStudySessionList(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      navigate(`/programmes`);
    }
  };

  useEffect(() => {
    getStudySessionDetail();
  }, []);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Study Sessions</h2>
          </div>
        </div>
        {studySessionList.length > 0 ? (
          <CustomMUICard data={studySessionList} handleClick={handleClick} />
        ) : (
          <RecordNotFound title="Study Sessions Not Found" />
        )}
      </div>
    </>
  );
}
