import { useEffect, useState } from "react";
// material
import { Box, Card, CircularProgress, Stack } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { booksListing } from "src/DAL/Books/Books";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import LockIcon from "@mui/icons-material/Lock";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
export default function BooksList() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [books, setBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const booksList = async () => {
    const result = await booksListing();
    if (result.code == 200) {
      setIsLoading(false);
      setBooks(result.books);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const handleNavigateDetail = (value) => {
    if (value.lock_status === true) {
    } else {
      navigate(`/books/detail-book/${value._id}`);
    }
  };
  useEffect(() => {
    booksList();
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <h2>Books </h2>
          </div>

          {books.length < 1 ? (
            <RecordNotFound title="Books" />
          ) : (
            books.map((value, index) => {
              return (
                <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                  {/* <div
                    className="card mt-4 pods-cards-shadow cursor h-100"
                    style={{ position: "relative", cursor: "pointer" }}>
                    {value.lock_status === true ? (
                      <button
                        variant="contained"
                        className="small-contained-button program-lock-icon"
                        style={{
                          zIndex: 9,
                          top: "30%",
                          left: "26%",
                          fontSize: "14px",
                          position: "absolute",
                          padding: "10px 20px",
                        }}>
                        <LockIcon />
                        &nbsp;&nbsp;Unlock Access
                      </button>
                    ) : (
                      ""
                    )}
                    <img
                      src={s3baseUrl + value.image.thumbnail_1}
                      className="card-img-top pods-image"
                      alt="Book"
                      onClick={() => handleNavigateDetail(value)}
                    />
                    <div
                      className="card-body d-flex flex-column pb-0"
                      style={{ minHeight: "fit-content" }}>
                      <div className="flex-grow-1"></div>
                      <div className="row">
                        <div className="col-12">
                          <h3
                            className="h2-heading"
                            onClick={() => handleNavigateDetail(value)}>
                            {htmlDecode(value.name)}
                          </h3>
                        </div>

                        <div className="menu-option"></div>
                      </div>
                      <p className="programme-card-desc">
                        {htmlDecode(value.description)}
                      </p>
                    </div>
                  </div> */}
                  <Card
                    className="programm-card h-100 secondary-background"
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => handleNavigateDetail(value)}>
                    <Box sx={{ position: "relative", cursor: "pointer" }}>
                      {value.lock_status === true ? (
                        <button
                          variant="contained"
                          className="small-contained-button program-lock-icon"
                          style={{
                            zIndex: 9,
                            top: "45%",
                            left: "22%",
                            fontSize: "14px",
                            position: "absolute",
                            padding: "10px 20px",
                          }}>
                          <LockIcon />
                          &nbsp;&nbsp;Unlock Access
                        </button>
                      ) : (
                        ""
                      )}
                      <img
                        src={s3baseUrl + value.image.thumbnail_1}
                        className="card-img-top pods-image"
                        alt="Book"
                        onClick={() => handleNavigateDetail(value)}
                      />
                    </Box>

                    <Stack spacing={2} className="programme-content mt-auto">
                      <h3>{htmlDecode(value.name)}</h3>
                      <p className="programme-card-desc">
                        {htmlDecode(value.description)}
                      </p>
                    </Stack>
                  </Card>
                </div>
              );
            })
          )}
        </div>
      </div>
    </>
  );
}
