import { invokeApi } from "../../bl_libs/invokeApi";

export const PaymentRequestsList = async (data) => {
  const requestObj = {
    path: `api/payment_request`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const requestDetailApi = async (request_slug) => {
  const requestObj = {
    path: `api/payment_request/${request_slug}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const get_site_setting = async () => {
  const requestObj = {
    path: `api/site_setting/`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const pay_now_for_subscription = async (postData) => {
  const requestObj = {
    path: `api/member/pay_now_by_member_app`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };

  return invokeApi(requestObj);
};

export const get_intent_client_secret_for_one_time = async (postData) => {
  const requestObj = {
    path: `api/member/pay_one_time_by_member_app`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };

  return invokeApi(requestObj);
};

export const confirm_subscription_payment = async (postData) => {
  const requestObj = {
    path: `api/member/confirm_subscription_incomplete`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };

  return invokeApi(requestObj);
};

export const confirm_one_time_payment = async (postData) => {
  const requestObj = {
    path: `api/member/change_one_time_payment_status_by_member_app`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: postData,
  };

  return invokeApi(requestObj);
};
