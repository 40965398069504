import PropTypes from "prop-types";
// material
import { Grid } from "@mui/material";
import ProgrammesCard from "./ProgrammesCard";
import ProgrammeMainCard from "./ProgrammeMainCard";
import RecordNotFound from "./RecordNotFound";

// ----------------------------------------------------------------------

ProgrammesList.propTypes = {
  products: PropTypes.array.isRequired,
};

export default function ProgrammesList({ programmes, ...other }) {
  return (
    <div className="row">
      {programmes.length < 1 ? (
        <RecordNotFound title="Programmes" />
      ) : (
        programmes.map((programm) => (
          <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
            <ProgrammesCard programm={programm} />
          </div>
        ))
      )}
    </div>
  );
}
