import React, { useState, useEffect } from "react";
import {
  useNavigate,
  useParams,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Container,
  Grid,
  Typography,
  Button,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RatingCard from "../../components/_dashboard/programmes/RatingCard";
import ProgrammDetailTab from "../../components/_dashboard/programmes/ProgrammDetailTab";
import { programmDetail } from "../../DAL/Programmes/Programmes";
import { htmlDecode } from "../../utils/convertHtml";
import { s3baseUrl } from "../../config/config";
import { styled } from "@mui/material/styles";
import { ReactVideoPlayer } from "src/components";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function ProgrammesDetail(props) {
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [programmesDetail, setProgrammesDetail] = useState({});
  const [lessonsList, setLessonsList] = useState([]);
  const [resourcesList, setResourcesList] = useState([]);
  const [reviewsList, setReviewsList] = useState([]);
  const [recordingList, setRecordingList] = useState([]);
  const [programSection, setProgramSection] = useState([]);
  const [studySession, setStudySession] = useState([]);
  const ProgrammesImgStyle = styled("img")({
    width: "100%",
    height: "100%",
    objectFit: "cover",
  });

  const getProgrammesDetail = async () => {
    const result = await programmDetail(params.slug);
    if (result.code === 200) {
      console.log(result, "result");
      setProgrammesDetail(result.program);
      setLessonsList(result.lesson);
      setResourcesList(result.program_document);
      setReviewsList(result.program_review);
      setRecordingList(result.recording_list);
      setProgramSection(result.program_section);
      setStudySession(result.study_session);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const get_programme_image = () => {
    if (programmesDetail.program_lock_status === true) {
      return programmesDetail.locked_program_info.image;
    } else {
      return programmesDetail.program_images.thumbnail_1;
    }
  };
  const get_programme_video = () => {
    if (programmesDetail.program_lock_status === true) {
      return programmesDetail.locked_program_info.video_url;
    } else {
      return programmesDetail.video_url;
    }
  };
  const get_audio_url = () => {
    if (programmesDetail.program_lock_status === true) {
      return "";
    } else {
      return programmesDetail.audio_file;
    }
  };

  const get_detailed_description = () => {
    if (programmesDetail.program_lock_status === true) {
      return htmlDecode(
        programmesDetail.locked_program_info.detailed_description
      );
    } else {
      return programmesDetail.detailed_description;
    }
  };
  useEffect(() => {
    getProgrammesDetail();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(recordingList, "recordingList");
  return (
    <div className="container h-100">
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate("/programmes")}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      </div>
      {programmesDetail.program_used_for == "outside_portal" ? (
        <div className="row section-space">
          <div className="col-12">
            <h1>
              {location.state
                ? htmlDecode(location.state.title)
                : htmlDecode(programmesDetail.title)}
            </h1>
          </div>
        </div>
      ) : (
        <span></span>
      )}
      <div className="h-100">
        {programmesDetail.program_used_for == "outside_portal" ? (
          <>
            {programmesDetail.program_lock_status === false ? (
              <>
                <iframe
                  src={programmesDetail?.outside_portal_link}
                  height="100%"
                  width="100%"
                  title="Loading"></iframe>
              </>
            ) : (
              <div className="row media-margin">
                <div className="col-12">
                  {get_programme_video() ? (
                    <ReactVideoPlayer url={get_programme_video()} />
                  ) : get_programme_image() ? (
                    // <ProgrammesImgStyle
                    //   src={s3baseUrl + get_programme_image()}
                    // />
                    <img src={s3baseUrl + get_programme_image()} />
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-12 section-space set-image-center">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        programmesDetail?.locked_program_info
                          ?.detailed_description,
                    }}></div>
                </div>
              </div>
            )}
          </>
        ) : (
          <div>
            <div className="col-12">
              {programmesDetail.program_lock_status === false ? (
                <button
                  className="small-contained-button float-end mt-1"
                  onClick={() =>
                    navigate(`/programmes/${params.slug}/lessons`)
                  }>
                  View Lessons
                </button>
              ) : (
                <button
                  className="small-contained-button float-end mt-1 disabled"
                  disabled="disabled">
                  View Lessons
                </button>
              )}
            </div>
            <div className="row section-space">
              <div className="col-12">
                <h1>
                  {location.state
                    ? htmlDecode(location.state.title)
                    : htmlDecode(programmesDetail.title)}
                </h1>
              </div>
            </div>
            <div className="row media-margin">
              <div className="col-12">
                {get_programme_video() ? (
                  <ReactVideoPlayer url={get_programme_video()} />
                ) : get_programme_image() ? (
                  // <ProgrammesImgStyle src={s3baseUrl + get_programme_image()} />
                  <img src={s3baseUrl + get_programme_image()} />
                ) : (
                  ""
                )}
              </div>
              {get_audio_url() && (
                <div className="col-12 section-space">
                  <audio
                    controlsList="nodownload"
                    className="w-100"
                    src={s3baseUrl + get_audio_url()}
                    controls
                  />
                </div>
              )}

              <div className="col-12 section-space set-image-center">
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      programmesDetail.program_lock_status === true
                        ? programmesDetail?.locked_program_info
                            ?.detailed_description
                        : programmesDetail?.detailed_description,
                  }}></div>
              </div>
            </div>

            <div className="row section-space">
              <div className="col-12">
                <ProgrammDetailTab
                  lessons={lessonsList}
                  resources={resourcesList}
                  reviews={reviewsList}
                  recordingList={recordingList}
                  programSection={programSection}
                  studySession={studySession}
                  programLockStatus={programmesDetail.program_lock_status}
                  programSlug={programmesDetail.program_slug}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProgrammesDetail;
