import { CircularProgress, Container, IconButton } from "@mui/material";
import React, { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { booksDetailApi } from "src/DAL/Books/Books";
import { makeStyles } from "@mui/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Document, Page, pdfjs } from "react-pdf";
import { s3baseUrl } from "src/config/config";
import { get_book_pdf_data_api } from "../../DAL/Books/Books";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loadingpdf: {
    marginLeft: "60%",
    marginTop: "40%",
  },
}));

export default function BooksDetail() {
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const iframeRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDocumentLoading, setIsDocumentLoading] = useState(true);
  const [bookDetail, setBookDetail] = useState("");
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfData, setPdfData] = useState("");

  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log(numPages, "numPagesnumPages");
    setIsDocumentLoading(false);
    setNumPages(numPages);
  };
  const handleContextMenu = (e) => {
    e.preventDefault(); // Prevent the default context menu behavior
  };
  const goToPrevPage = () => setPageNumber((prevPage) => prevPage - 1);
  const goToNextPage = () => setPageNumber((prevPage) => prevPage + 1);
  const booksDetail = async () => {
    setIsLoading(true);
    const result = await booksDetailApi(params.id);
    if (result.code == 200) {
      setBookDetail(result.book);
      setIsLoading(false);
      let data = { file_name: result.book.pdf_file };
      let response = await get_book_pdf_data_api(data);
      if (iframeRef.current) {
        iframeRef.current.contentDocument.open();
        iframeRef.current.contentDocument.write(response);
        iframeRef.current.contentDocument.close();
      }
      setIsDocumentLoading(false);
      // setPdfData(file_re);
    }
  };
  useEffect(() => {
    booksDetail();
  }, []);
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <Container>
      <div className="row mobile-margin display-flex">
        <div className="col-12">
          <IconButton
            className="back-screen-button"
            onClick={() => navigate(`/books`)}
          >
            <ArrowBackIcon />
          </IconButton>
        </div>
        <div className="row section-space">
          <div className="col-12">
            <h2>{bookDetail?.name}</h2>
          </div>
        </div>
        <div className="row">
          <div
            // style={{ width: 600 }}
            onContextMenu={handleContextMenu}
            className="pdf-view"
          >
            {isDocumentLoading ? (
              //   <CircularProgress
              //     className={classes.loadingpdf}
              //     color="primary"
              //   />
              ""
            ) : (
              <>
                {/* <nav className="pdf-nav">
                  <div>
                    <button
                      // className="small-contained-button me-2"
                      className={`small-contained-button-pdf me-2 ${
                        pageNumber === 1 ? "disabled" : ""
                      }`}
                      disabled={pageNumber === 1}
                      onClick={goToPrevPage}
                    >
                      Prev
                    </button>
                    <button
                      className={`small-contained-button-pdf me-2 ${
                        pageNumber === numPages ? "disabled" : ""
                      }`}
                      disabled={pageNumber === numPages}
                      onClick={goToNextPage}
                    >
                      Next
                    </button>
                  </div>
                  <div>
                    <h5 className="pdf-pages">
                      Page {pageNumber} of {numPages}
                    </h5>
                  </div>
                </nav> */}
              </>
            )}
            {/* <div
              dangerouslySetInnerHTML={{
                __html: pdfData,
              }}
            ></div> */}
            {/* <Document
              file={`data:application/pdf;base64,${pdfData}`}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={pageNumber}
                width={600}
                // renderTextLayer={true}
                // renderAnnotationLayer={false}
                // customTextRenderer={true}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                customTextRenderer={false}
              />
            </Document> */}
            {/* <Document
              file={s3baseUrl + bookDetail.pdf_file}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                pageNumber={pageNumber}
                width={600}
                // renderTextLayer={true}
                // renderAnnotationLayer={false}
                // customTextRenderer={true}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                customTextRenderer={false}
              />
            </Document> */}
            {isDocumentLoading && (
              <div className="loading-pdf mt-5">
                <h2>Loading Pdf ...</h2>
              </div>
            )}
            <iframe
              ref={iframeRef}
              style={{ width: "100%", height: "100vh" }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}
