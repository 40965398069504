import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import barchartfill from "@iconify/icons-eva/bar-chart-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import lockFill from "@iconify/icons-eva/lock-fill";
import personAddFill from "@iconify/icons-eva/person-add-fill";
import alertTriangleFill from "@iconify/icons-eva/alert-triangle-fill";
import {
  DashboardIcons,
  DnDashboardIcons,
  HkDashboardIcons,
  AffirmationIcons,
  DnAffirmationIcons,
  HkAffirmationIcons,
  GoalStatmentIcons,
  DnGoalStatmentIcons,
  HkGoalStatmentIcons,
  GratitudeIcons,
  DnGratitudeIcons,
  HkGratitudeIcons,
  MemoriesIcons,
  DnMemoriesIcons,
  HkMemoriesIcons,
  PodsIcons,
  DnPodsIcons,
  HkPodsIcons,
  ProfileIcons,
  DnProfileIcons,
  HkProfileIcons,
  ProgrammeIcons,
  DnProgrammeIcons,
  HkProgrammeIcons,
  QuotesIcons,
  DnQuotesIcons,
  HkQuotesIcons,
  VaultIcons,
  DnVaultIcons,
  HkVaultIcons,
  DayPlanIcons,
  DnDayPlanIcons,
  mkDashboard,
  mkmemory,
  mkgratitude,
  mkpods,
  mkquotes,
  mkvault,
  mkprofile,
  mkaffirmation,
  mkprogram,
} from "src/assets";
import { project_name } from "../../config/config";
import { ContentSettingApi } from "src/DAL/ContentSetting/ContentSetting";
import { useEffect, useState } from "react";
import { mkgoalStatement } from "../../assets";
// ----------------------------------------------------------------------
let dashboard_icon = "";
let goal_statement_icon = "";
let programmes_icon = "";
let vault_icon = "";
let pods_icon = "";
let affirmations_icon = "";
let gratitude_icon = "";
let quotes_icon = "";
let memories_icon = "";
let calender_icon = "";
let support_ticket_icon = "";
let day_plan_icon = "";
if (
  project_name === "DD" ||
  project_name === "BASIC_DEV" ||
  project_name === "PGI_DEV"
) {
  dashboard_icon = DashboardIcons;
  goal_statement_icon = GoalStatmentIcons;
  programmes_icon = ProgrammeIcons;
  vault_icon = VaultIcons;
  pods_icon = PodsIcons;
  affirmations_icon = AffirmationIcons;
  gratitude_icon = GratitudeIcons;
  quotes_icon = QuotesIcons;
  memories_icon = MemoriesIcons;
  calender_icon = DashboardIcons;
  support_ticket_icon = DashboardIcons;
  day_plan_icon = DayPlanIcons;
} else if (project_name === "HINA_KHAN") {
  dashboard_icon = HkDashboardIcons;
  goal_statement_icon = HkGoalStatmentIcons;
  programmes_icon = HkProgrammeIcons;
  vault_icon = HkVaultIcons;
  pods_icon = HkPodsIcons;
  affirmations_icon = HkAffirmationIcons;
  gratitude_icon = HkGratitudeIcons;
  quotes_icon = HkQuotesIcons;
  memories_icon = HkMemoriesIcons;
  calender_icon = HkDashboardIcons;
  support_ticket_icon = HkDashboardIcons;
} else if (project_name === "DANIELLE") {
  dashboard_icon = DnDashboardIcons;
  goal_statement_icon = DnGoalStatmentIcons;
  programmes_icon = DnProgrammeIcons;
  vault_icon = DnVaultIcons;
  pods_icon = DnPodsIcons;
  affirmations_icon = DnAffirmationIcons;
  gratitude_icon = DnGratitudeIcons;
  quotes_icon = DnQuotesIcons;
  memories_icon = DnMemoriesIcons;
  calender_icon = DnMemoriesIcons;
  support_ticket_icon = DnMemoriesIcons;
} else if (project_name == "MARKUS_DEV") {
  dashboard_icon = mkDashboard;
  goal_statement_icon = mkgoalStatement;
  programmes_icon = mkprogram;
  vault_icon = mkvault;
  pods_icon = mkpods;
  affirmations_icon = mkaffirmation;
  gratitude_icon = mkgratitude;
  quotes_icon = mkquotes;
  memories_icon = mkmemory;
  calender_icon = mkDashboard;
  support_ticket_icon = mkDashboard;
} else {
  dashboard_icon = fileTextFill;
  goal_statement_icon = fileTextFill;
  programmes_icon = fileTextFill;
  vault_icon = fileTextFill;
  pods_icon = fileTextFill;
  affirmations_icon = fileTextFill;
  gratitude_icon = fileTextFill;
  quotes_icon = fileTextFill;
  memories_icon = fileTextFill;
  calender_icon = fileTextFill;
  support_ticket_icon = alertTriangleFill;
}

const getIcon = (name) => {
  if (
    project_name === "DD" ||
    project_name === "BASIC_DEV" ||
    project_name === "PGI_DEV" ||
    project_name === "HINA_KHAN" ||
    project_name === "MARKUS_DEV"
  ) {
    return <img className="navbar-icon" src={name} width={22} height={22} />;
  } else {
    return <Icon className="navbar-icon" icon={name} width={22} height={22} />;
  }
};

const sidebarConfig = (navbarList, consultantInfo, userInfo) => {
  console.log(navbarList, "navbarList");
  let sidebarMenus = [];
  sidebarMenus.push({
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon(dashboard_icon),
    matches: ["/dashboard", "/post-detail", "/video-detail"],
  });
  if (
    userInfo &&
    userInfo.goal_statement_status &&
    navbarList &&
    navbarList.goal_statement
  ) {
    sidebarMenus.push({
      title: "Goal Statement",
      path: "/goal-statement",
      icon: getIcon(goal_statement_icon),
    });
  }

  if (navbarList && navbarList.programmes) {
    sidebarMenus.push({
      title: "Programmes",
      path: "/programmes",
      icon: getIcon(programmes_icon),
      matches: [
        "/programmes",
        "/lessons",
        "/recordings",
        "/lessons-recordings",
        "/study-session",
      ],
    });
  }
  if (navbarList && navbarList.the_vault) {
    sidebarMenus.push({
      title: "The Vault",
      path: "/vault",
      icon: getIcon(vault_icon),
    });
  }
  if (navbarList && navbarList.books) {
    sidebarMenus.push({
      title: "Books",
      path: "/books",
      icon: getIcon(programmes_icon),
    });
  }

  // if (navbarList && navbarList.the_vault) {
  //   sidebarMenus.push({
  //     title: "consultant recordings",
  //     path: "/consultant-recordings",
  //     icon: getIcon(vault_icon),
  //   });
  // }
  consultantInfo !== null &&
    consultantInfo !== undefined &&
    consultantInfo !== "undefined" &&
    consultantInfo.status === true &&
    consultantInfo.team_type !== "team" &&
    sidebarMenus.push({
      title:
        consultantInfo.first_name +
        " " +
        consultantInfo.last_name +
        "'s Recording",
      path: "/consultant-recordings",
      icon: getIcon(vault_icon),
    });
  if (navbarList && navbarList.pods) {
    sidebarMenus.push({
      title: "Pods",
      path: "/pods",
      icon: getIcon(pods_icon),
    });
  }
  if (navbarList && navbarList.affirmations) {
    sidebarMenus.push({
      title: "Affirmations",
      path: "/affirmations",
      icon: getIcon(affirmations_icon),
    });
  }
  if (navbarList && navbarList.gratitude) {
    sidebarMenus.push({
      title: "Gratitude",
      path: "/gratitude",
      icon: getIcon(gratitude_icon),
    });
  }
  if (navbarList && navbarList.quotes) {
    sidebarMenus.push({
      title: "Quotes",
      path: "/quotes",
      icon: getIcon(quotes_icon),
    });
  }
  if (navbarList && navbarList.memories) {
    sidebarMenus.push({
      title: "Memories",
      path: "/memories",
      icon: getIcon(memories_icon),
    });
  }
  if (navbarList && navbarList.calendar) {
    sidebarMenus.push({
      title: "Calender",
      path: "/calender",
      icon: getIcon(calender_icon),
    });
  }
  if (navbarList && navbarList.support_ticket) {
    sidebarMenus.push({
      title: "Support Ticket",
      path: "/support-ticket",
      icon: getIcon(support_ticket_icon),
    });
  }
  // if (navbarList && navbarList.study_session) {
  //   sidebarMenus.push({
  //     title: "Study Session",
  //     path: "/study-session",
  //     icon: getIcon(support_ticket_icon),
  //   });
  // }
  // if (navbarList && navbarList.support_ticket) {
  //   sidebarMenus.push({
  //     title: "90 Day Plan",
  //     icon: getIcon(day_plan_icon),
  //     child_options: [
  //       {
  //         title: "90 Day Formula",
  //         path: "/ninety-day-plan",
  //         icon: getIcon(day_plan_icon),
  //       },
  //       {
  //         title: "90 Day Tracker",
  //         path: "/ninety-day-tracker",
  //         icon: getIcon(day_plan_icon),
  //       },
  //     ],
  //   });
  // }

  return sidebarMenus;
};
export default sidebarConfig;
