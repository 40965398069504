import React, { useState, useEffect } from "react";
import PinterestLayout from "src/components/PinterestLayout/Pinterest";
import { quotesListing } from "../../DAL/Quotes/Quotes";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function Quotes() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [quotesData, setQuotesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { contentSettingData } = useContentSetting();
  const getQuotesListing = async () => {
    const result = await quotesListing();
    if (result.code === 200) {
      let quotationArray = [];
      let quotationObject = {};
      result.quotation.map((quotation, index) => {
        quotationObject = {
          pinteres_id: quotation._id,
          pinterestImage: quotation.quotation_images.thumbnail_1,
          pinterestDescription: quotation.description,
        };
        quotationArray.push(quotationObject);
      });
      setQuotesData(quotationArray);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const handleDetailPage = () => {};
  useEffect(() => {
    getQuotesListing();
  }, []);
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 dashboard_description">
            {contentSettingData && contentSettingData.quotes_description ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.quotes_description,
                }}
              ></div>
            ) : (
              <h2 className="quotes-heading margin-quotes">Quotes</h2>
            )}
          </div>
        </div>
      </div>
      <div className="container">
        <PinterestLayout
          notFoundtitle="Gratitude"
          data={quotesData}
          handleDetailPage={handleDetailPage}
        />
      </div>
      {/* <div className="grid-container">
        <h2>Quotes</h2>
      </div>

      <div className="grid-container mt-4">
        {itemData.map((value, index) => {
          console.log(value, "data of map");
          return (
            <div>
              <img className="grid-item" src={value.img} alt="" />
              <p>{value.description}</p>
            </div>
          );
        })}
      </div> */}
    </>
  );
}

export default Quotes;
