const ProgrammesDetailNewPage = () => {
  return (
    <>
      <iframe
        src="https://dynamiclogix.com/"
        height="100%"
        width="100%"
        title="Iframe Example"
      ></iframe>
    </>
  );
};

export default ProgrammesDetailNewPage;
