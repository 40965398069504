import React from "react";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

import ShowCard from "./ShowCard";
import ChangeCreditCard from "./ChangeCreditCard";
// import ChangeCard from "./ChnageCard";

const card = {
  name: "jhon",
  expiry: "12/12",
  cardNumber: "5151515151515151",
  cvc: "123",
};

const PaymentForm = () => {
  const [inputs, setInputs] = React.useState({
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  });

  const handleInputChange = (e) => {
    // const { name, value } = e.target;
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleInputFocus = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      focus: name,
    }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  // var size = Object.keys(card).length;
  // console.log(size, "ooooooooooooooooo");
  return (
    <div className="container">
      {Object.keys(card).length == 0 ? (
        <ChangeCreditCard />
      ) : (
        <ShowCard card={card} />
      )}
    </div>
  );
};
export default PaymentForm;
