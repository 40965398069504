import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";

const ShowEvenetSlider = ({ headingData, data }) => {
  var data_length = data.length;
  let set_infinite = true;
  if (data_length < 3) {
    set_infinite = false;
  }
  var settings = {
    dots: false,
    infinite: set_infinite,
    speed: 1000,
    slidesToShow: 3,
    autoplay: true,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <h2 className="mb-5">{htmlDecode(headingData)}</h2>
      <Slider {...settings} className="event-slider">
        {data.map((value, index) => (
          <div className="slider-box" key={value._id}>
            <img src={s3baseUrl + value.images.thumbnail_2} className="w-100" />
            <div className="join-now-btn text-center w-100">
              {value.button_link && (
                <a href={value.button_link} target="_blank">
                  <button className="small-contained-button">
                    {value.button_text
                      ? htmlDecode(value.button_text)
                      : "JOIN NOW"}
                  </button>
                </a>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
export default ShowEvenetSlider;
