import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { chanePassword } from "src/DAL/Login/Login";
import { profileImage } from "src/assets";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import VideocamIcon from "@mui/icons-material/Videocam";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { InputBase } from "@mui/material";

const useStyles = makeStyles({
  resize: {
    fontSize: 20,
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 480,
  maxWidth: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 1,
};

export default function CreatePost({
  openCreateModal,
  setOpenCreateModal,
  handleShowVideoBox,
  handleShowImageBox,
  showImageBox,
  showVideoBox,
  setShowVideoBox,
  setShowImageBox,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setOpenCreateModal(true);
  const handleClose = () => setOpenCreateModal(false);
  const [previews, setPreviews] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = React.useState("");
  const [inputs, setInputs] = useState({
    password: "",
    repeatPassword: "",
    oldPassword: "",
  });

  const handleRemove = () => {
    setPreviews("");
    setImage("");
  };

  const handleUpload = (event) => {
    setImage(event.target.files[0]);
    const preview = URL.createObjectURL(event.target.files[0]);
    setPreviews(preview);
  };

  const handleCreatePost = async (e) => {
    e.preventDefault();
    // setIsLoading(true);
    // if (inputs.password !== inputs.repeatPassword) {
    //   enqueueSnackbar("Password And Repeat Password not matched", {
    //     variant: "error",
    //   });
    //   setIsLoading(false);
    // } else {
    //   const formData = new FormData();
    //   formData.append("old_password", inputs.oldPassword);
    //   formData.append("password", inputs.password);
    //   formData.append("confirm_password", inputs.repeatPassword);
    //   const result = await chanePassword(formData);
    //   if (result.code === 200) {
    //     setInputs({});
    //     setIsLoading(false);
    //     handleClose();
    //     enqueueSnackbar("Password Changed Successfully", {
    //       variant: "success",
    //     });
    //   } else {
    //     setIsLoading(false);
    //     enqueueSnackbar(result.message, { variant: "error" });
    //   }
    // }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  return (
    <div>
      <Modal
        disableTypography="true"
        open={openCreateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={handleCreatePost} className="create-post-title-form">
          <Box sx={style} className="modal-theme">
            <div
              className="text-center modalIcon clo-12 create-post-title-box"
              onClick={() => {
                handleClose();
              }}
            >
              <h2>Create post</h2>
              <hr />
              <span>x</span>
            </div>
            <div className="col-12">
              <div className="post-creator">
                <img src={profileImage} alt="" />
                <div className="creator-name">
                  <h3>Leeanna Alvord</h3>
                  <select name="" id="">
                    <option value="1">Public</option>
                    <option value="1">Win</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12 mt-3">
              {/* <TextField
                // className="text-muted"
                className="textfield"
                InputLabelProps={{ style: { fontSize: 14 } }}
                inputProps={{
                  style: {
                    color: "white",
                    borderColor: "transparent",
                    disableUnderline: true,
                  },
                }}
                id="outlined-basic"
                label="What's in your mind?"
                variant="outlined"
                fullWidth
                multiline
                rows={5}
                size="small"
                autoFocus
                name="oldPassword"
                // value={inputs.oldPassword}
                onChange={handleChange}
                required={true}
              /> */}
              <InputBase
                style={{ color: "white", width: "100%", whiteSpace: "wrap" }}
                sx={{ ml: 1, flex: 1 }}
                placeholder="What's on your mind?"
                inputProps={{
                  "aria-label": "What's on your mind?",
                }}
                multiline
                minRows="2"
              />
              {/* <TextField
                id="standard-textarea"
                label="Multiline Placeholder"
                placeholder="Placeholder"
                multiline
                variant="standard"
              /> */}
              {/* <TextField
                id="standard-multiline-flexible"
                label="Multiline"
                multiline
                // maxRows={4}
                // value={value}
                // onChange={handleChange}
                variant="standard"
              /> */}
            </div>
            {showVideoBox && (
              <div className="col-12 mt-3 video-url-field search-input">
                {/* <TextField
                  // className="text-muted"
                  inputProps={{
                    style: { color: "white" },
                  }}
                  InputLabelProps={{ style: { fontSize: 14 } }}
                  id="outlined-basic"
                  label="Video URL"
                  variant="outlined"
                  fullWidth
                  size="small"
                  autoFocus
                  name="oldPassword"
                  // value={inputs.oldPassword}
                  onChange={handleChange}
                  required={true}
                /> */}
                <input
                  type="text"
                  className="form-control"
                  placeholder="Video URL"
                />
                <span
                  className="video-url-cross"
                  onClick={() => {
                    setShowVideoBox(false);
                  }}
                >
                  x
                </span>
              </div>
            )}
            {showImageBox && (
              <>
                <div className="col-md-12 mt-2 d-flex w-100">
                  {previews && (
                    <span className="preview mt-2">
                      <span onClick={handleRemove}>x</span>
                      <img src={previews} />
                    </span>
                  )}
                  {previews == "" && (
                    <span className="upload-button mt-2 w-100">
                      <input
                        color="primary"
                        accept="image/*"
                        type="file"
                        id="icon-button-file"
                        style={{ display: "none" }}
                        name="affirmationImage"
                        onChange={handleUpload}
                      />
                      <label htmlFor="icon-button-file">
                        <span
                          onClick={() => {
                            setShowImageBox(false);
                          }}
                        >
                          x
                        </span>
                        <p className="mt-3">
                          Add Photo <br />
                          <CloudUploadIcon />
                        </p>
                      </label>
                    </span>
                  )}
                </div>
              </>
            )}
            <div className="col-12">
              <div className="video-image-selection">
                <div
                  onClick={handleShowImageBox}
                  className={`selection-box ${showImageBox ? "selected" : ""}`}
                >
                  <PhotoCameraIcon />
                </div>
                <div
                  onClick={handleShowVideoBox}
                  className={`selection-box ${showVideoBox ? "selected" : ""}`}
                >
                  <VideocamIcon />
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <button className="small-contained-button post-submit-btn">
                {isLoading ? "Posting..." : "Post"}
              </button>
            </div>
          </Box>
        </form>
      </Modal>
    </div>
  );
}
