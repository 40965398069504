import { Avatar, Box, List } from "@mui/material";
import { ContentSettingApi } from "src/DAL/ContentSetting/ContentSetting";
import { memberDetail } from "src/DAL/Profile/Profile";
import NavSectionItem from "./NavSectionItem";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { useEffect } from "react";
import { s3baseUrl } from "src/config/config";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";

export default function NavSection({ navConfig }) {
  const is_seen_video = _get_is_seen_video_localStorage();
  const {
    handleContentSettingData,
    handleDashboardSettingData,
    handleDashboardTestimonialData,
    handleDashboardEventData,
    handleAdminTimeZone,
    handleUserInfo,
    handleConsultantInfo,
    navbarList,
    dashboardSettingData,
    consultantInfo,
    setProjectInfo,
  } = useContentSetting();

  const getContentSetting = async () => {
    const result = await ContentSettingApi();
    if (result.code === 200) {
      if (result.content_setting === null) {
        handleDashboardSettingData({});
        handleContentSettingData({});
        handleDashboardTestimonialData([]);
        handleDashboardEventData([]);
        handleAdminTimeZone("");
        return;
      }
      localStorage.setItem(
        `content_setting`,
        JSON.stringify(result.content_setting.client_content_setting)
      );
      localStorage.setItem(
        `client_dashboard_setting`,
        JSON.stringify(result.content_setting.client_dashboard_setting)
      );
      localStorage.setItem(`testmonial`, JSON.stringify(result.testmonial));
      localStorage.setItem(`home_event`, JSON.stringify(result.home_event));
      localStorage.setItem(`admin_time_zone`, JSON.stringify(result.time_zone));
      localStorage.setItem(
        `project_info`,
        JSON.stringify(result.content_setting.client_general_setting)
      );
      setProjectInfo(result.content_setting.client_general_setting);
      handleAdminTimeZone(result.time_zone);
      handleDashboardEventData(result.home_event);
      handleDashboardTestimonialData(result.testmonial);
      handleContentSettingData(result.content_setting.client_content_setting);
      handleDashboardSettingData(
        result.content_setting.client_dashboard_setting
      );
      if (
        dashboardSettingData.video_url !==
          result.content_setting.client_dashboard_setting.video_url ||
        result.content_setting.client_dashboard_setting.video_type == "repeat"
      ) {
        localStorage.setItem("is_seen_video", "No");
      }
      const member_result = await memberDetail();
      if (member_result.code === 200) {
        localStorage.setItem(`user_data`, JSON.stringify(member_result.member));
        localStorage.setItem(
          `consultant_data`,
          JSON.stringify(member_result.member_consultant)
        );
        handleUserInfo(member_result.member);
        handleConsultantInfo(member_result.member_consultant);
        if (is_seen_video && is_seen_video === "Yes") {
        } else {
          localStorage.setItem("is_seen_video", "No");
        }
      } else {
        enqueueSnackbar(member_result.message, { variant: "error" });
      }
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getContentSetting();
  }, []);

  return (
    <Box>
      <List disablePadding>
        {navConfig.map((item, i) => {
          return <NavSectionItem data={item} />;
        })}
      </List>
      {consultantInfo !== null &&
        consultantInfo !== undefined &&
        consultantInfo !== "undefined" &&
        consultantInfo.team_type !== "team" &&
        consultantInfo.status === true && (
          <div className="associate-section">
            <p className="mt-3">Your Associate is: </p>
            <div className="row">
              <div className="col-4">
                <div className="d-flex circle-image">
                  <Avatar
                    src={s3baseUrl + consultantInfo?.image.thumbnail_1}
                    alt="photoURL">
                    {consultantInfo?.first_name[0] +
                      consultantInfo?.last_name[0]}
                  </Avatar>
                </div>
              </div>
              <div className="col-8">
                <p className="card-title mt-4 mb-0">
                  {consultantInfo?.first_name + " " + consultantInfo?.last_name}
                </p>
              </div>
            </div>
          </div>
        )}
    </Box>
  );
}
