import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  communityBannerImg,
  communityImage1,
  communityImage13,
  communityImage14,
  communityImage15,
  communityImage16,
  communityImage17,
  communityImage18,
  communityImage2,
  communityImage3,
  communityImage4,
  communityImage5,
  communityImage6,
  communityImage7,
  communityImage8,
  communityJadeImg,
  communityLine1,
  communityVideoImg,
  profileBg,
  profileImage,
} from "src/assets";
import LeftPart from "./LeftPart";
import RightPart from "./RightPart";
import MainPart from "./MainPart";
import TabsPopover from "./TabsPopover";
import CommunitySlider from "./CommunitySlider";
import CreatePost from "./CreatePost";
import { ReactVideoPlayer } from "src/components";
import OpenCreatePostCard from "./OpenCreatePostCard";
function Community(props) {
  const [showInfoBox, setShowInfoBox] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [showImageBox, setShowImageBox] = useState(false);
  const [showVideoBox, setShowVideoBox] = useState(false);
  const navigate = useNavigate();

  const handleShowImageBox = () => {
    setShowImageBox(true);
    setShowVideoBox(false);
  };

  const handleShowVideoBox = () => {
    setShowVideoBox(true);
    setShowImageBox(false);
  };

  const handleOpenSimpleBox = () => {
    setOpenCreateModal(true);
    setShowImageBox(false);
    setShowVideoBox(false);
  };

  const imagesArray = [
    {
      video_url: "https://player.vimeo.com/video/713449736",
      image: communityImage13,
    },
    {
      video_url: "https://player.vimeo.com/video/713449736",
      image: communityImage14,
    },
    {
      video_url: "https://player.vimeo.com/video/713449736",
      image: communityImage15,
    },
    {
      video_url: "https://player.vimeo.com/video/713449736",
      image: communityImage16,
    },
    {
      video_url: "https://player.vimeo.com/video/713449736",
      image: communityImage17,
    },
    {
      video_url: "https://player.vimeo.com/video/713449736",
      image: communityImage18,
    },
  ];

  const handleChangePage = () => {
    navigate(`/video-detail`);
  };

  return (
    <div className="container new-community-page">
      <section className="banner-section mt-4">
        <div className="container position-relative">
          <img src={communityBannerImg} alt="" className="img-fluid" />
          <div className="jade-div">
            <img src={communityJadeImg} alt="" className="img-fluid jade-img" />
            <img src={communityImage1} alt="" className="img-fluid img-left" />
          </div>
        </div>
      </section>
      <section className="membership-section text-center">
        <div className="container">
          <div className="row justify-content-center  pt-3">
            <div className="col-4 col-lg-2">
              <p>Dynamite</p>
            </div>
            <div className="col-4 col-lg-7">
              <p>Elite</p>
            </div>
            <div className="col-4 col-lg-2">
              <p>Mastery</p>
            </div>
          </div>
          <div className="back-light-div px-2 d-none d-lg-block">
            <div className="row justify-content-center">
              <div className="col-md-3 col-lg-3">
                <div className="row ">
                  <div className="col-6">
                    <div className="img-back-border">
                      <img src={communityImage2} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="img-back-border">
                      <img src={communityImage3} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-6">
                <div className="row ">
                  <div className="col-4">
                    <div className="img-back-border">
                      <img src={communityImage4} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="img-back-border">
                      <img src={communityImage6} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="img-back-border">
                      <img src={communityImage5} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-lg-3">
                <div className="row ">
                  <div className="col-6">
                    <div className="img-back-border">
                      <img src={communityImage7} alt="" className="img-fluid" />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="img-back-border">
                      <img src={communityImage8} alt="" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-none d-lg-flex">
            <div className="col-md-4 col-lg-3 mt-3 position-relative">
              <div className="row">
                <div className="col-6">
                  <button>GUEST</button>
                </div>
                <div className="col-6">
                  <button>MEMBERSHIP</button>
                  <div className="position-img">
                    <img src={communityLine1} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-6 mt-3 position-relative">
              <div className="row">
                <div className="col-6 col-md-4">
                  <button>TIR MEMBER</button>
                </div>
                <div className="col-6 col-md-4">
                  <button>IGNITE MEMBER</button>
                </div>
                <div className="col-6 col-md-4 mt-3 mt-md-0">
                  <a href="#">
                    <button>ALUMNI MEMBER</button>
                  </a>
                  <div className="position-img-1">
                    <img src={communityLine1} alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 mt-3">
              <div className="row">
                <div className="col-6">
                  <button>INNER CIRCLE</button>
                </div>
                <div className="col-6">
                  <button>COMING SOON</button>
                </div>
              </div>
            </div>
          </div>
          {/* small screen  */}
          <div className="back-light-div p-4 d-block d-lg-none">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="row ">
                  <div className="col-6">
                    <div className="img-back-border-1">
                      <img
                        src={communityImage2}
                        alt=""
                        className="img-fluid mx-auto"
                      />
                    </div>
                    <div className="mt-4">
                      <button>GUESS</button>
                    </div>
                  </div>
                  <div className="col-6">
                    <img
                      src={communityImage3}
                      alt=""
                      className="img-fluid mx-auto"
                    />
                    <div className="mt-4">
                      <button>MEMBERSHIP</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-5 mt-md-0">
                <div className="row ">
                  <div className="col-6">
                    <img
                      src={communityImage4}
                      alt=""
                      className="img-fluid mx-auto"
                    />
                    <div className="mt-4">
                      <button>TIR MEMBER</button>
                    </div>
                  </div>
                  <div className="col-6">
                    <img
                      src={communityImage6}
                      alt=""
                      className="img-fluid mx-auto"
                    />
                    <div className="mt-4">
                      <button>IGNITE MEMBER</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mt-5">
                <div className="row ">
                  <div className="col-6 col-md-4">
                    <img
                      src={communityImage5}
                      alt=""
                      className="img-fluid mx-auto"
                    />
                    <div className="mt-4">
                      <button>ALUMNI MEMBER</button>
                    </div>
                  </div>
                  <div className="col-6 col-md-4">
                    <img
                      src={communityImage7}
                      alt=""
                      className="img-fluid mx-auto"
                    />
                    <div className="mt-4">
                      <button>INNER CIRCLE</button>
                    </div>
                  </div>
                  <div className="col-md-4 mt-5 mt-md-0">
                    <img
                      src={communityImage8}
                      alt=""
                      className="img-fluid mx-auto"
                    />
                    <div className="mt-4">
                      <button className="w-50">COMING SOON</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="success-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pe-lg-5">
              <h1>ACHIEVING SUCCESS IS NOT </h1>
              <h1>A SECRET, IT’S A SCIENCE</h1>
              <h2>
                <span>MINDSET</span> &nbsp; 2022 &nbsp; 1SEASON
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida. Risus commodo viverra
                maecenas accumsan lacus vel facilisis.
              </p>
              <div className="btn-wrapper">
                <button className="play-btn me-2" onClick={handleChangePage}>
                  <i className="fa-solid fa-play" />
                  &nbsp; Play
                </button>
                {/* <button className="more-info-btn">
                  <i className="fa-solid fa-circle-info" />
                  &nbsp;More Info
                </button> */}
              </div>
            </div>
            <div className="col-lg-6 mt-5 mt-lg-0">
              <ReactVideoPlayer url="https://vimeo.com/686454572" />
              {/* <img src={communityVideoImg} alt="" className="img-fluid" /> */}
            </div>
          </div>
        </div>
      </section>
      <section className="slider py-5">
        <div className="container bg-img">
          <CommunitySlider data={imagesArray} />
          <div className="row">
            {/* <div className="col-12 col-lg-6">
              <div className="row">
                <div className="col-12 col-md-4 slider-lower-buttons mt-4">
                  <button>SEARCH</button>
                </div>
                <div className="col-12 col-md-4 slider-lower-buttons mt-4">
                  <button>FAVOURITES</button>
                </div>
                <div className="col-12 col-md-4 slider-lower-buttons mt-4">
                  <button>SERIES</button>
                </div>
              </div>
            </div> */}
            {/* <div className="col-12 col-lg-6 text-center text-md-end slider-lower-buttons mt-4">
              <button
                onClick={() => {
                  setOpenCreateModal(true);
                }}
              >
                CREATE POST
              </button>
            </div> */}
          </div>
        </div>
      </section>
      <div className="row">
        <div className="col-md-12 col-lg-3 order-2 order-lg-1">
          <LeftPart showInfoBox={showInfoBox} setShowInfoBox={setShowInfoBox} />
        </div>
        <div className="col-md-12 col-lg-6 order-1 order-lg-2">
          <OpenCreatePostCard
            setOpenCreateModal={setOpenCreateModal}
            handleOpenSimpleBox={handleOpenSimpleBox}
            handleShowImageBox={handleShowImageBox}
            handleShowVideoBox={handleShowVideoBox}
          />
          <MainPart showInfoBox={showInfoBox} setShowInfoBox={setShowInfoBox} />
        </div>
        <div className="col-md-12 col-lg-3 order-3">
          <RightPart />
        </div>
      </div>
      <CreatePost
        handleShowImageBox={handleShowImageBox}
        setShowVideoBox={setShowVideoBox}
        setShowImageBox={setShowImageBox}
        handleShowVideoBox={handleShowVideoBox}
        showVideoBox={showVideoBox}
        showImageBox={showImageBox}
        openCreateModal={openCreateModal}
        setOpenCreateModal={setOpenCreateModal}
      />
    </div>
  );
}

export default Community;
